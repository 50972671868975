import React, { useEffect } from 'react';
import {
	Box,
	Button,
	Typography,
	Grid,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Card,
	CardHeader,
	CardContent,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import momentTimezone from 'moment-timezone';
import {
	getSingleDevice,
	getDeviceTransactions,
	getAccountsAttachedToDevice,
	deleteEvidence,
	setSingleDevice,
	setGetSingleDeviceStatus,
	changeTransactionFilterField,
	resetTransactionFilters,
	addEvidenceComment,
	exportTransactionsToJSON,
	exportTransactionsToCSV,
	resetDeviceVector,
	getDeviceEvidenceHistory,
} from 'actions/devicesActions';
import { LOADING, ERROR } from 'constants/apiStatuses';
import {
	EvidenceManager,
	Pagination,
	FilterProvider,
	DeleteModal,
} from 'components';
import deviceTransactionsColumns from 'constants/columns/deviceTransactions';
import accountsColumns from 'constants/columns/devicesAccounts';
import './styles.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getRowColorByScore } from 'helpers/getTableRowColor';
import TransactionFilters from 'containers/TransactionsPage/TransactionFilters';
import notification from 'helpers/notification';
import defaultSortModels from 'constants/defaultHeaderSortModels';
import { dateFormatter } from 'helpers/formatters';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';
import { getRules } from '../../redux/actions/rulesActions';

const Device = (props) => {
	const navigate = useNavigate();
	const params = useParams();

	const modalTypes = {
		exportToCSV: 'exportToCSV',
		exportToJSON: 'exportToJSON',
		resetVectorCount: 'resetVectorCount',
	};

	const exportLimit = process.env.REACT_APP_TRANSACTIONS_EXPORT_LIMIT;

	const [activeModal, setActiveModal] = React.useState(null);
	const [transactionTableSortModel, setTransactionTableSortModel] =
		React.useState(defaultSortModels.transaction);

	const {
		device,
		transactionFilters,
		resetTransactionFilters,
		getDeviceStatus,
		getSingleDevice,
		deleteEvidence,
		addEvidenceComment,
		resetVector = true,
		resetVectorStatus,
		singleDeviceTransactions,
		getDeviceTransactions,
		singleDeviceAccounts,
		getAccountsAttachedToDevice,
		changeFilterField,
		setSingleDevice,
		setGetSingleDeviceStatus,
		exportTransactionsToJSONStatus,
		exportTransactionsToCSVStatus,
		exportTransactionsToJSON,
		exportTransactionsToCSV,
		resetDeviceVector,
		userInfo,
		evidenceHistory,
		getDeviceEvidenceHistory,
		rules,
		getRules,
	} = props;

	const isUserAdmin = userInfo.role === 'admin';

	const {
		data: transactions,
		total: transactionsTotalAmount,
		status: deviceTransactionsStatus,
	} = singleDeviceTransactions;

	const {
		data: paginatedAccounts,
		total: accountsTotalAmount,
		status: getAccountsStatus,
	} = singleDeviceAccounts;

	const deviceId = props.id || params.id;

	useEffect(() => {
		getSingleDevice(deviceId);

		return () => {
			setSingleDevice(null);
			setGetSingleDeviceStatus(LOADING);
			resetTransactionFilters(null);
		};
	}, []);

	useEffect(() => {
		getRules(transactionFilters);
	}, []);

	if (getDeviceStatus === LOADING && !device) {
		return <div>Loading...</div>;
	}

	const handleGoBack = () => navigate(-1);

	if (getDeviceStatus === ERROR) {
		return (
			<Box className='deviceContainerError'>
				<div>Device with id {deviceId} does not exist</div>
				<Button color='primary' variant='contained' onClick={handleGoBack}>
					Go back
				</Button>
			</Box>
		);
	}
	const {
		osVersion,
		accounts,
		gpu,
		osType,
		con,
		mem,
		width,
		height,
		depth,
		app,
		product,
		lang,
		lStore,
		sStore,
		vendor,
		cookies,
		video,
		audio,
		media,
		plugins,
		audioFP,
		webglFP,
		canvasFP,
		fonts,
		analyser,
		audioC,
		permissions,
		tz,
		creation,
		creationDate,
		id,
		...restDevice
	} = device ?? {};

	const getTableClass = (actionStatus, state) => {
		return `deviceTable ${
			actionStatus === LOADING ? 'disablePageButton' : ''
		} ${state ? 'removePageButton' : ''}`;
	};

	const accordionClasses = { root: 'deviceInfoCard' };
	const accordionSummaryClasses = { content: 'deviceInfoSummary' };
	const accordionSummaryClassName = `deviceInfoSummary deviceInfoSummaryHeader`;

	const isExportToJSONTransactions = exportTransactionsToJSONStatus === LOADING;
	const isExportToCSVTransactions = exportTransactionsToCSVStatus === LOADING;
	const isDeviceVectorReset = resetVectorStatus === LOADING;

	const handleCloseModal = () => {
		setActiveModal(null);
	};
	const handleOpenExportToJSONModal = () => {
		setActiveModal(modalTypes.exportToJSON);
	};
	const handleOpenExportToCSVModal = () => {
		setActiveModal(modalTypes.exportToCSV);
	};
	const handleOpenDeviceVectorModal = () => {
		setActiveModal(modalTypes.resetVectorCount);
	};
	const handleResetDeviceVector = () => {
		resetDeviceVector(deviceId, handleCloseModal);
	};

	const handleExport = (exportFunction) => {
		if (singleDeviceTransactions.total > exportLimit) {
			handleCloseModal();
			notification.invoke(`Export limit is ${exportLimit} entities.`, {
				variant: 'error',
			});
		} else {
			exportFunction(
				transactionFilters,
				transactionTableSortModel,
				deviceId,
				handleCloseModal
			);
		}
	};

	const handleExportTransactionsToJSONClick = () => {
		handleExport(exportTransactionsToJSON);
	};
	const handleExportTransactionsToCSVClick = () => {
		handleExport(exportTransactionsToCSV);
	};

	const renderModal = () => {
		switch (activeModal) {
			case modalTypes.exportToJSON:
				return (
					<DeleteModal
						title='Export device transactions to JSON'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${singleDeviceTransactions.total} transactions?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportTransactionsToJSONClick}
						load={isExportToJSONTransactions}
					/>
				);
			case modalTypes.exportToCSV:
				return (
					<DeleteModal
						title='Export device transactions'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${singleDeviceTransactions.total} transactions?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportTransactionsToCSVClick}
						load={isExportToCSVTransactions}
					/>
				);
			case modalTypes.resetVectorCount:
				return (
					<DeleteModal
						title='Reset device vector count'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to reset device vector count?`}
						onSubmit={handleResetDeviceVector}
						load={isDeviceVectorReset}
					/>
				);
			default:
				return null;
		}
	};

	const pageTitle = <Typography variant='h3'>Device</Typography>;
	const pageActions = (
		<>
			{resetVector && isUserAdmin && (
				<Button
					className='deviceResetVectorButton'
					color='secondary'
					variant='contained'
					onClick={handleOpenDeviceVectorModal}
				>
					Reset Vector
				</Button>
			)}
		</>
	);

	const handleGetPaginatedAccounts = (params) =>
		getAccountsAttachedToDevice({
			...params,
			accountsId: device?.accounts,
			deviceId,
		});
	const handleAccountsTableRowClick = ({ id }) => navigate(`/accounts/${id}`);

	const handleGetPaginatedTransactions = (params) =>
		getDeviceTransactions({ ...params, deviceId });
	const handleTransactionsTableRowClick = ({ id }) =>
		navigate(`/transactions/${id}`);

	const getRadiatedFromUrl = (id, type) => `/${type}s/${id}`;

	const getRowColorByMark = (params) => {
		return params.row.isMarked ? 'tableCellRed' : 'tableCellGreen';
	};

	return (
		<Card>
			<CardHeader
				className='deviceContainer'
				title={pageTitle}
				action={pageActions}
			/>
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<Typography variant='h5'>Device info</Typography>
						<KeyValueTable width={40}>
							<RowValue oKey='ID:' oValue={id} />
							<RowValue oKey='GPU:' oValue={gpu} />
							<RowValue oKey='Platform:' oValue={osType} />
							<RowValue oKey='Platform version:' oValue={osVersion} />
							<RowValue oKey='Memory:' oValue={mem} />
							<RowValue oKey='Concurrency hardware:' oValue={con} />
							<RowValue oKey='Width:' oValue={width} />
							<RowValue oKey='Height:' oValue={height} />
							<RowValue oKey='Depth:' oValue={depth} />
						</KeyValueTable>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='h5'>Browser info</Typography>
						<KeyValueTable width={100}>
							<RowValue oKey='Browser name:' oValue={app} />
							<RowValue oKey='Product:' oValue={product} />
							<RowValue
								oKey='Languages:'
								oValue={Array.isArray(lang) ? lang.join(',') : lang}
							/>
							<RowValue oKey='Local storage:' oValue={lStore} />
							<RowValue oKey='Session storage:' oValue={sStore} />
							<RowValue oKey='Vendor:' oValue={vendor} />
							<RowValue oKey='Cookies:' oValue={cookies} />
						</KeyValueTable>
					</Grid>

					<Grid item xs={6}>
						<Accordion elevation={0} classes={accordionClasses}>
							<AccordionSummary
								classes={accordionSummaryClasses}
								expandIcon={<ExpandMoreIcon />}
								className={accordionSummaryClassName}
							>
								<Typography variant='h5'>Other Info</Typography>
							</AccordionSummary>
							<AccordionDetails className='deviceInfoSummary'>
								<KeyValueTable>
									<RowValue oKey='Analyser:' oValue={analyser} />
									<RowValue oKey='Audio:' oValue={audio} />
									<RowValue oKey='Audio Context:' oValue={audioC} />
									<RowValue oKey='Fonts:' oValue={fonts} />
									<RowValue oKey='Media:' oValue={media} />
									<RowValue oKey='Permissions:' oValue={permissions} />
									<RowValue oKey='Video:' oValue={video} />
									<RowValue oKey='Plugins:' oValue={plugins} />
									<RowValue
										oKey='Timezone:'
										oValue={`UTC ${momentTimezone.tz(tz).format('Z')} ${tz}`}
									/>

									{Object.entries(restDevice).map(([key, value]) => {
										return (
											<RowValue key={key} oKey={`${key}:`} oValue={value} />
										);
									})}

									<RowValue oKey='creation:' oValue={dateFormatter(creation)} />
									<RowValue
										oKey='creationDate:'
										oValue={dateFormatter(creationDate)}
									/>
								</KeyValueTable>
							</AccordionDetails>
						</Accordion>
					</Grid>
					<Grid item xs={6}>
						<Accordion elevation={0} classes={accordionClasses}>
							<AccordionSummary
								classes={accordionSummaryClasses}
								expandIcon={<ExpandMoreIcon />}
								className={accordionSummaryClassName}
							>
								<Typography variant='h5'>Fingerprints</Typography>
							</AccordionSummary>
							<AccordionDetails className='deviceInfoSummary'>
								<KeyValueTable>
									<RowValue oKey='Audio FP:' oValue={audioFP} />
									<RowValue oKey='Canvas FP:' oValue={canvasFP} />
									<RowValue oKey='Webgl FP:' oValue={webglFP} />
								</KeyValueTable>
							</AccordionDetails>
						</Accordion>
					</Grid>
				</Grid>
			</CardContent>
			<CardContent>
				<EvidenceManager
					type='device'
					getEvidences={getDeviceEvidenceHistory}
					getRadiatedFromUrl={getRadiatedFromUrl}
					deleteRequest={deleteEvidence}
					evidenceHistory={evidenceHistory}
					addCommentRequest={addEvidenceComment}
					createEvidenceUrl={`/new-device-evidence/${deviceId}`}
					entityId={deviceId}
					getAccount={paginatedAccounts}
				/>
			</CardContent>
			<CardContent>
				<Typography variant='h5' className='deviceSubTitle'>
					Accounts, attached to device
				</Typography>
				<Pagination
					getItems={handleGetPaginatedAccounts}
					defaultSortModel={defaultSortModels.account}
					seconds={200}
				>
					{({
						page,
						pageSize,
						rowsPerPageOptions,
						isArrowBlocked,
						handleHeaderFilterChange,
						sortModel,
						handlePaginationModelChange,
					}) =>
						paginatedAccounts && (
							<DataGrid
								paginationMode='server'
								autoHeight
								className={getTableClass(getAccountsStatus, isArrowBlocked)}
								columns={accountsColumns}
								rows={paginatedAccounts}
								rowCount={accountsTotalAmount}
								onRowClick={handleAccountsTableRowClick}
								getRowClassName={getRowColorByMark}
								disableColumnMenu={true}
								paginationModel={{ page, pageSize }}
								pageSizeOptions={rowsPerPageOptions}
								onPaginationModelChange={handlePaginationModelChange}
								sortingMode='server'
								onSortModelChange={handleHeaderFilterChange}
								sortModel={sortModel}
							/>
						)
					}
				</Pagination>
			</CardContent>
			<CardContent>
				<Typography variant='h5' className='deviceSubTitle'>
					Device transactions
				</Typography>
				<Pagination
					getItems={handleGetPaginatedTransactions}
					changeFilterField={changeFilterField}
					resetFilters={resetTransactionFilters}
					initialFilters={transactionFilters}
					filters={transactionFilters}
					defaultSortModel={defaultSortModels.transaction}
					exception='exception'
					seconds={210}
					setExternalSortModel={setTransactionTableSortModel}
				>
					{({
						page,
						handleChangeFilters,
						handleResetFilters,
						handleRefresh,
						pageSize,
						rowsPerPageOptions,
						isArrowBlocked,
						handleHeaderFilterChange,
						sortModel,
						handlePaginationModelChange,
					}) => (
						<>
							<Box className='deviceFiltersContainer'>
								{transactions && (
									<TransactionFilters
										filters={transactionFilters}
										handleChangeFilters={handleChangeFilters}
										handleRefresh={() => handleRefresh(pageSize)}
										handleResetFilters={handleResetFilters}
										handleExportToJSONButtonClick={handleOpenExportToJSONModal}
										handleExportToCSVButtonClick={handleOpenExportToCSVModal}
										exclude={{
											deviceId: true,
											exportButton: !singleDeviceTransactions.total,
										}}
										rules={rules}
										isFromAccount={true}
									/>
								)}
							</Box>
							{transactions && (
								<FilterProvider
									changeFilterField={changeFilterField}
									updateURL={false}
								>
									<DataGrid
										paginationMode='server'
										autoHeight
										className={getTableClass(
											deviceTransactionsStatus,
											isArrowBlocked
										)}
										columns={deviceTransactionsColumns}
										rows={transactions || []}
										rowCount={transactionsTotalAmount}
										onRowClick={handleTransactionsTableRowClick}
										getRowClassName={getRowColorByScore}
										disableColumnMenu={true}
										paginationModel={{ page, pageSize }}
										pageSizeOptions={rowsPerPageOptions}
										onPaginationModelChange={handlePaginationModelChange}
										sortingMode='server'
										onSortModelChange={handleHeaderFilterChange}
										sortModel={sortModel}
									/>
								</FilterProvider>
							)}
						</>
					)}
				</Pagination>
			</CardContent>
			{renderModal()}
		</Card>
	);
};

const mapStateToProps = ({ devices, auth, rules }) => ({
	device: devices.singleDevice,
	transactionFilters: devices.transactionFilters,
	getDeviceStatus: devices.getSingleDeviceStatus,
	singleDeviceTransactions: devices.singleDeviceTransactions,
	singleDeviceAccounts: devices.singleDeviceAccounts,
	exportTransactionsToJSONStatus: devices.exportTransactionsToJSONStatus,
	exportTransactionsToCSVStatus: devices.exportTransactionsToCSVStatus,
	resetVectorStatus: devices.resetVectorStatus,
	userInfo: auth.userInfo,
	evidenceHistory: devices.evidenceHistory,
	rules: rules.data.rules ? rules.data.rules : null,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleDevice: (id) => dispatch(getSingleDevice(id)),
	getDeviceTransactions: (body) => dispatch(getDeviceTransactions(body)),
	changeFilterField: (value) => dispatch(changeTransactionFilterField(value)),
	getAccountsAttachedToDevice: (body) =>
		dispatch(getAccountsAttachedToDevice(body)),
	deleteEvidence: (body, onLoad) => dispatch(deleteEvidence(body, onLoad)),
	addEvidenceComment: (body, onLoad) =>
		dispatch(addEvidenceComment(body, onLoad)),
	setSingleDevice: (device) => dispatch(setSingleDevice(device)),
	setGetSingleDeviceStatus: (status) =>
		dispatch(setGetSingleDeviceStatus(status)),
	resetTransactionFilters: (body) => dispatch(resetTransactionFilters(body)),
	exportTransactionsToJSON: (filters, sorting, deviceId, onLoad) =>
		dispatch(exportTransactionsToJSON(filters, sorting, deviceId, onLoad)),
	exportTransactionsToCSV: (filters, sorting, deviceId, onLoad) =>
		dispatch(exportTransactionsToCSV(filters, sorting, deviceId, onLoad)),
	resetDeviceVector: (id, onLoad) => dispatch(resetDeviceVector(id, onLoad)),
	getDeviceEvidenceHistory: (body) => dispatch(getDeviceEvidenceHistory(body)),
	getRules: (transactionFilters) => dispatch(getRules(transactionFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Device);
