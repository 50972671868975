import React, { useEffect } from 'react';
import {
	Box,
	Typography,
	Table,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	Button,
	CircularProgress,
	IconButton,
	Link,
	Tooltip,
} from '@mui/material';
import { connect } from 'react-redux';
import { useCSVReader } from 'react-papaparse';
import {
	getImportedEvidenceInfo,
	importEvidence,
	setImportedEvidencesInfo,
} from 'actions/evidencesActions';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { LOADING } from 'constants/apiStatuses';
import './styles.css';
import { getReasonNameById } from 'helpers/reasonsMethods';
import { getReasons } from 'actions/reasonsActions';
import {
	getBadEvidences,
	getExpiresDateFormatted,
	normalizeDate,
} from 'helpers/checkImportEvidences';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const ImportEvidence = (props) => {
	const { CSVReader } = useCSVReader();

	const [failedReasons, setFailedReasons] = React.useState(null);
	const {
		getImportedEvidenceInfo,
		getImportedEvidenceInfoStatus,
		importedEvidenceInfo,
		importEvidence,
		importEvidenceStatus,
		setImportedEvidencesInfo,
		evidenceTypes,
		getReasons,
	} = props;

	const handleFileLoad = (data) => {
		const parsed = data.data
			.map((row) => {
				return {
					'account.name': row[0]?.trim(),
					'evidence.reason': row[1]?.trim(),
					radiate: row[2]?.trim() ?? 'false',
					comment:
						row[3]?.trim() ??
						`Mass-Upload ${new Date()
							.toISOString()
							.substr(0, 4 + 1 + 2 + 1 + 2)}`,
					expires: normalizeDate(row[4]?.trim()),
				};
			})
			.filter(
				(e) =>
					e['evidence.reason'] &&
					e['account.name'] &&
					e['radiate'] &&
					e['comment']
			);
		const badReasons = getBadEvidences(evidenceTypes, parsed);
		if (!badReasons.length) {
			setFailedReasons(null);
			getImportedEvidenceInfo(parsed);
		} else {
			setFailedReasons(badReasons);
		}
	};

	const handleImport = () => {
		importEvidence(importedEvidenceInfo);
		console.log('importedEvidenceInfo', importedEvidenceInfo);
	};

	useEffect(() => {
		getReasons();
		return () => {
			setImportedEvidencesInfo(null);
		};
	}, []);

	const getAccountsCount = (accounts) => {
		if (!accounts) return 0;
		return !!accounts ? accounts - 1 : accounts;
	};

	const isGettingInfo = getImportedEvidenceInfoStatus === LOADING;
	const isImporting = importEvidenceStatus === LOADING;
	const showProgress = isGettingInfo || isImporting;
	return (
		<Box
			display='flex'
			justifyContent='center'
			alignItems='center'
			flexDirection='column'
		>
			<CSVReader onUploadAccepted={handleFileLoad}>
				{({ getRootProps }) => (
					<div {...getRootProps()} className='eviFileInput'>
						<Typography variant='h4'>Your csv file</Typography>
						<div className='eviFileInputBody'>
							First column: account name, Second column: evidence text, <br />
							Third column: true/false radiation, Fourth column: comment text,{' '}
							<br />
							Fifth column: evidence timestamp of expiration. <br />
							Note: Please do NOT use comma in text.
						</div>
					</div>
				)}
			</CSVReader>
			{showProgress && (
				<>
					<CircularProgress style={{ marginTop: 20 }} />
					{isGettingInfo && <p>Getting info...</p>}
					{isImporting && <p>Importing...</p>}
				</>
			)}
			{failedReasons && (
				<Box>
					<Typography variant='h5'>
						Can't import the data. Please, check the next reasons from the file:
					</Typography>
					{failedReasons?.map((elem) => (
						<Typography
							className='eviFailedReasonItem'
							key={elem['evidence.reason']}
							variant='h5'
						>
							{elem['evidence.reason']}
						</Typography>
					))}
				</Box>
			)}
			{importedEvidenceInfo && !failedReasons && (
				<>
					<Button
						color='primary'
						variant='contained'
						className='eviButton'
						onClick={handleImport}
						disabled={showProgress}
					>
						Import
					</Button>
					<Table className='eviTable'>
						<TableHead>
							<TableRow>
								<TableCell align='center'>Account name</TableCell>
								<TableCell align='center'>Account exists</TableCell>
								<TableCell align='center'>Evidence</TableCell>
								<TableCell align='center'>
									Evidence for account exists
								</TableCell>
								<TableCell align='center'>Radiate</TableCell>
								<TableCell align='center'>Accounts</TableCell>
								<TableCell align='center'>Devices</TableCell>
								<TableCell align='center'>Comment</TableCell>
								<TableCell align='center'>Expires</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{importedEvidenceInfo.map((importedEvidenceInfo, index) => (
								<TableRow
									key={`${importedEvidenceInfo['account.name']}_${index}`}
								>
									<TableCell align='center' style={{ cursor: 'pointer' }}>
										<Tooltip
											title={
												importedEvidenceInfo?.accountId && (
													<Link
														component={IconButton}
														style={{ padding: '5px 0' }}
														target='_blank'
														href={`${window.location.origin}/accounts/${importedEvidenceInfo.accountId}`}
													>
														<ExitToAppIcon
															fontSize='small'
															className='eviTooltipIcon'
														/>
													</Link>
												)
											}
											placement='bottom'
										>
											{importedEvidenceInfo['account.name']}
										</Tooltip>
									</TableCell>
									<TableCell align='center'>
										{importedEvidenceInfo.doesAccountExist ? (
											<CheckIcon className='eviCheckIcon' />
										) : (
											<CloseIcon className='eviCloseIcon' />
										)}
									</TableCell>
									<TableCell align='center'>
										{getReasonNameById(
											evidenceTypes,
											importedEvidenceInfo['evidence.reason']
										)}
									</TableCell>
									<TableCell align='center'>
										{importedEvidenceInfo.doesEvidenceForAccountExist ? (
											<CheckIcon className='eviCheckIcon' />
										) : (
											<CloseIcon className='eviCloseIcon' />
										)}
									</TableCell>
									<TableCell align='center'>
										{importedEvidenceInfo['radiate'] === 'true' ? 'yes' : 'no'}
									</TableCell>
									<TableCell align='center'>
										{getAccountsCount(
											importedEvidenceInfo['radiateEntities'].account?.length
										)}
									</TableCell>
									<TableCell align='center'>
										{importedEvidenceInfo['radiateEntities'].device?.length}
									</TableCell>
									<TableCell align='center' style={{ maxWidth: 200 }}>
										{importedEvidenceInfo['comment']}
									</TableCell>
									<TableCell align='center' style={{ maxWidth: 200 }}>
										{getExpiresDateFormatted(importedEvidenceInfo['expires'])}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</>
			)}
		</Box>
	);
};

const mapStateToProps = ({ evidences, reasons }) => ({
	importedEvidenceInfo: evidences.importedEvidenceInfo,
	getImportedEvidenceInfoStatus: evidences.getImportedEvidenceInfoStatus,
	importEvidenceStatus: evidences.importEvidenceStatus,
	evidenceTypes: reasons.reasons,
});

const mapDispatchToProps = (dispatch) => ({
	getImportedEvidenceInfo: (data) => dispatch(getImportedEvidenceInfo(data)),
	importEvidence: (data) => dispatch(importEvidence(data)),
	setImportedEvidencesInfo: (data) => dispatch(setImportedEvidencesInfo(data)),
	getReasons: () => dispatch(getReasons),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportEvidence);
