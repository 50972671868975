import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Box,
	Button,
	Typography,
	Card,
	CardHeader,
	CardContent,
} from '@mui/material';
import notification from 'helpers/notification';
import { DataGrid } from '@mui/x-data-grid';
import devicesColumns from 'constants/columns/accountsDevices';
import accountTransactionsColumns from 'constants/columns/accountTransactions';
import {
	getSingleAccount,
	deleteEvidence,
	getDevicesAttachedToAccounts,
	getAccountTransactions,
	setSingleAccount,
	setGetSingleAccountStatus,
	changeTransactionFilterField,
	deleteAccount,
	resetTransactionFilters,
	exportTransactionsToJSON,
	exportTransactionsToCSV,
	addEvidenceComment,
	copyEvidence,
	getAccountsEvidenceHistory,
} from 'actions/accountsActions';
import { LOADING, ERROR } from 'constants/apiStatuses';
import {
	EvidenceManager,
	Pagination,
	DeleteModal,
	FilterProvider,
} from 'components';
import './styles.css';
import { getRowColorByScore } from 'helpers/getTableRowColor';
import TransactionFilters from '../TransactionsPage/TransactionFilters';
import defaultSortModels from 'constants/defaultHeaderSortModels';
import { dateFormatter } from 'helpers/formatters';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';
import { getRules } from '../../redux/actions/rulesActions';

const Account = (props) => {
	const navigate = useNavigate();
	const params = useParams();

	const modalTypes = {
		exportToCSV: 'exportToCSV',
		exportToJSON: 'exportToJSON',
		deleteAccount: 'deleteAccount',
		radiateEvidence: 'radiateEvidence',
	};

	const exportLimit = process.env.REACT_APP_TRANSACTIONS_EXPORT_LIMIT;

	const {
		account,
		changeFilterField,
		transactionFilters,
		resetTransactionFilters,
		getAttachedDevicesStatus,
		userInfo,
		deleteAccount,
		getAccountStatus,
		deleteAccountStatus,
		createEvidenceStatus,
		copyAccountEvidence,
		getSingleAccount,
		deleteEvidence,
		addEvidenceComment,
		deleteButton = true,
		isTransaction = true,
		singleAccountDevices,
		singleAccountTransactions,
		getDevicesAttachedToAccounts,
		getAccountTransactions,
		setSingleAccount,
		setGetSingleAccountStatus,
		exportTransactionsToJSONStatus,
		exportTransactionsToJSON,
		exportTransactionsToCSVStatus,
		exportTransactionsToCSV,
		evidenceHistory,
		getAccountsEvidenceHistory,
		rules,
		getRules,
	} = props;

	const accountId = props.id || params.id;

	const [activeModal, setActiveModal] = React.useState(null);

	const [transactionTableSortModel, setTransactionTableSortModel] =
		React.useState(defaultSortModels.transaction);

	useEffect(() => {
		getSingleAccount(accountId);
		resetTransactionFilters(null);
		return () => {
			setSingleAccount(null);
			setGetSingleAccountStatus(LOADING);
		};
	}, [accountId]);

	useEffect(() => {
		return () => {
			setSingleAccount(null);
			setGetSingleAccountStatus(LOADING);
		};
	}, []);
	const getItems = React.useCallback(
		(params) => {
			getAccountTransactions({ ...params, accountId });
		},
		[accountId]
	);

	useEffect(() => {
		getRules(transactionFilters);
	}, []);

	const getDeviceItems = React.useCallback(
		(params) => {
			getDevicesAttachedToAccounts({
				...params,
				devicesId: account?.devices,
				accountId,
			});
		},
		[accountId, account?.devices]
	);

	const handleDeviceTableRowClick = React.useCallback(
		({ id }) => navigate(`/devices/${id}`),
		[]
	);
	const getDeviceTableRowClass = React.useCallback(
		(params) => (params.row.isMarked ? 'tableCellRed' : 'tableCellGreen'),
		[]
	);

	const handleTransactionTableRowClick = React.useCallback(
		({ id }) => navigate(`/transactions/${id}`),
		[]
	);

	const tableColumns = devicesColumns?.map((column) => {
		if (column.field === 'lastSeen') {
			return {
				...column,
				headerName: isTransaction
					? 'Last connection'
					: 'Last connection with this account',
			};
		}
		return column;
	});

	const handleGoBack = () => navigate(-1);

	const tableCustomClasses = {
		row: 'devicesTableRow',
	};

	const {
		total: totalDevicesAttachedToAccount,
		data: devicesAttachedToAccount,
	} = singleAccountDevices;

	const {
		total: totalAccountTransactions,
		data: accountTransactions,
		status: transactionStatus,
	} = singleAccountTransactions;

	if (getAccountStatus === LOADING && !account) {
		return <div>Loading...</div>;
	}

	if (getAccountStatus === ERROR) {
		return (
			<Box className='accContainerError'>
				<div>Account with id {accountId} does not exist</div>
				<Button color='primary' variant='contained' onClick={handleGoBack}>
					Go back
				</Button>
			</Box>
		);
	}

	const { id, name, devices, creationDate } = account;

	const handleDeleteAccount = () => {
		deleteAccount(accountId, handleCloseModal, (id) =>
			navigate(`/accounts/${id}`)
		);
	};

	const getTableClass = (actionStatus, state) => {
		return `accTable ${actionStatus === LOADING ? 'disablePageButton' : ''} ${
			state ? 'removePageButton' : ''
		}`;
	};

	const isAccountDeleting = deleteAccountStatus === LOADING;
	const isExportingToJSON = exportTransactionsToJSONStatus === LOADING;
	const isExportingToCSV = exportTransactionsToCSVStatus === LOADING;
	const isEvidenceCreated = createEvidenceStatus === LOADING;

	const handleCloseModal = () => {
		setActiveModal(null);
	};
	const handleOpenExportToJSONModal = () => {
		setActiveModal(modalTypes.exportToJSON);
	};
	const handleOpenExportToCSVModal = () => {
		setActiveModal(modalTypes.exportToCSV);
	};
	const handleOpenRadiateEvidenceModal = () => {
		setActiveModal(modalTypes.radiateEvidence);
	};
	const handleOpenDeleteAccountModal = () => {
		setActiveModal(modalTypes.deleteAccount);
	};

	const handleExport = (exportFunction) => {
		if (singleAccountTransactions.total > exportLimit) {
			handleCloseModal();
			notification.invoke(`Export limit is ${exportLimit} entities.`, {
				variant: 'error',
			});
		} else {
			exportFunction(
				transactionFilters,
				transactionTableSortModel,
				accountId,
				handleCloseModal
			);
		}
	};

	const handleExportTransactionsToJSONClick = () => {
		handleExport(exportTransactionsToJSON);
	};

	const handleExportTransactionsToCSVClick = () => {
		handleExport(exportTransactionsToCSV);
	};

	const handleOpenGFATPage = () => {
		window.open(
			`${process.env.REACT_APP_GFAT_USER_PAGE_LINK}/${name}`,
			'_blank'
		);
	};

	const handleRadiateSuccess = () => {
		notification.invoke('Evidences radiated successfully', {
			variant: 'success',
		});
	};

	const handleRadiateModal = (accountName) => {
		if (accountName && accountName.trim()) {
			copyAccountEvidence(
				accountName,
				accountId,
				handleCloseModal,
				handleRadiateSuccess
			);
		}
	};

	const renderModal = () => {
		switch (activeModal) {
			case modalTypes.exportToCSV:
				return (
					<DeleteModal
						title='Export device transactions to CSV'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${singleAccountTransactions.total} transactions?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportTransactionsToCSVClick}
						load={isExportingToCSV}
					/>
				);
			case modalTypes.exportToJSON:
				return (
					<DeleteModal
						title='Export device transactions to JSON'
						onClose={handleCloseModal}
						modalBody={`Are you sure want to export ${singleAccountTransactions.total} transactions?`}
						modalHTML={<p>(Limit to export is {exportLimit})</p>}
						onSubmit={handleExportTransactionsToJSONClick}
						load={isExportingToJSON}
					/>
				);
			case modalTypes.radiateEvidence:
				return (
					<DeleteModal
						title='Radiate evidences to account'
						onClose={handleCloseModal}
						onSubmit={handleRadiateModal}
						modalBody='Enter account name'
						load={isEvidenceCreated}
						buttons={{ accept: 'Radiate', cancel: 'Cancel' }}
						input={{
							id: 'name',
							label: 'Account name',
							margin: 'dense',
							type: 'text',
							variant: 'standard',
						}}
					/>
				);
			case modalTypes.deleteAccount:
				return (
					<DeleteModal
						title='Deleting account'
						onSubmit={handleDeleteAccount}
						onClose={handleCloseModal}
						modalBody='Are you sure want to delete this account?'
						load={isAccountDeleting}
					/>
				);
			default:
				return null;
		}
	};

	const pageTitle = <Typography variant='h3'>Account</Typography>;
	const pageActions = (
		<>
			{!!evidenceHistory?.data?.length && deleteButton && (
				<Button
					variant='contained'
					color='secondary'
					className={`accMarginRight accRadiateEvidenceButton`}
					onClick={handleOpenRadiateEvidenceModal}
				>
					Radiate evidence to other account
				</Button>
			)}
			<Button
				variant='contained'
				color='success'
				onClick={handleOpenGFATPage}
				className='accMarginRight'
			>
				Open GFAT
			</Button>
			{userInfo?.role === 'admin' && deleteButton && (
				<Button
					color='secondary'
					variant='contained'
					className='accMarginRight'
					onClick={handleOpenDeleteAccountModal}
				>
					GDPR-DELETE
				</Button>
			)}
		</>
	);

	return (
		<Card>
			<CardHeader
				className='accContainer'
				title={pageTitle}
				action={pageActions}
			/>
			<CardContent>
				<KeyValueTable>
					<RowValue oKey='Account id:' oValue={id} />
					<RowValue oKey='Account name:' oValue={name} />
					<RowValue oKey='Device count:' oValue={devices?.length} />
					<RowValue
						oKey='First seen:'
						oValue={dateFormatter(account.firstSeen)}
					/>
					<RowValue
						oKey='Last seen:'
						oValue={dateFormatter(account.lastSeen)}
					/>
					<RowValue
						oKey='Creation date:'
						oValue={dateFormatter(creationDate)}
					/>
					{/*{Object.entries(restAccount).map(([key, value]) => {*/}
					{/*	return <RowValue oKey={key} oValue={value} key={key} />;*/}
					{/*})}*/}
				</KeyValueTable>
			</CardContent>
			<CardContent>
				<EvidenceManager
					getEvidences={getAccountsEvidenceHistory}
					type='account'
					evidenceHistory={evidenceHistory}
					createEvidenceUrl={`/new-account-evidence/${accountId}`}
					deleteRequest={deleteEvidence}
					addCommentRequest={addEvidenceComment}
					entityId={accountId}
				/>
			</CardContent>
			<CardContent>
				<Typography variant='h5' className='accSubTitle'>
					{isTransaction
						? 'Devices attached to account'
						: 'Devices attached to this account'}
				</Typography>
				<Pagination
					getItems={getDeviceItems}
					accountId={account.id}
					defaultSortModel={defaultSortModels.device}
				>
					{({
						page,
						pageSize,
						rowsPerPageOptions,
						isArrowBlocked,
						handleHeaderFilterChange,
						sortModel,
						handlePaginationModelChange,
					}) =>
						devicesAttachedToAccount && (
							<DataGrid
								rowCount={totalDevicesAttachedToAccount}
								autoHeight
								paginationMode='server'
								className={getTableClass(
									getAttachedDevicesStatus,
									isArrowBlocked
								)}
								columns={tableColumns}
								rows={devicesAttachedToAccount}
								onRowClick={handleDeviceTableRowClick}
								classes={tableCustomClasses}
								getRowClassName={getDeviceTableRowClass}
								disableColumnMenu={true}
								paginationModel={{ page, pageSize }}
								pageSizeOptions={rowsPerPageOptions}
								onPaginationModelChange={handlePaginationModelChange}
								sortingMode='server'
								onSortModelChange={handleHeaderFilterChange}
								sortModel={sortModel}
							/>
						)
					}
				</Pagination>
			</CardContent>
			<CardContent>
				<Typography variant='h5' className='accSubTitle'>
					Account transactions
				</Typography>
				<Pagination
					getItems={getItems}
					changeFilterField={changeFilterField}
					initialFilters={transactionFilters}
					filters={transactionFilters}
					resetFilters={resetTransactionFilters}
					defaultSortModel={defaultSortModels.transaction}
					accountId={account.id}
					exception='exception'
					seconds={190}
					setExternalSortModel={setTransactionTableSortModel}
				>
					{({
						page,
						handleChangeFilters,
						handleRefresh,
						handleResetFilters,
						pageSize,
						rowsPerPageOptions,
						isArrowBlocked,
						handleHeaderFilterChange,
						sortModel,
						handlePaginationModelChange,
					}) => (
						<>
							<Box className='accFiltersContainer'>
								{accountTransactions && (
									<TransactionFilters
										filters={transactionFilters}
										handleChangeFilters={handleChangeFilters}
										handleRefresh={() => handleRefresh(pageSize)}
										handleResetFilters={handleResetFilters}
										handleExportToJSONButtonClick={handleOpenExportToJSONModal}
										handleExportToCSVButtonClick={handleOpenExportToCSVModal}
										sortModel={transactionTableSortModel}
										exclude={{
											accountName: true,
											exportButton: !singleAccountTransactions.total,
										}}
										rules={rules}
										isFromAccount={true}
									/>
								)}
							</Box>
							{accountTransactions && (
								<FilterProvider
									changeFilterField={changeFilterField}
									updateURL={false}
								>
									<DataGrid
										rowCount={totalAccountTransactions}
										autoHeight
										paginationMode='server'
										className={getTableClass(transactionStatus, isArrowBlocked)}
										columns={accountTransactionsColumns}
										rows={accountTransactions}
										onRowClick={handleTransactionTableRowClick}
										classes={tableCustomClasses}
										getRowClassName={getRowColorByScore}
										disableColumnMenu={true}
										paginationModel={{ page, pageSize }}
										pageSizeOptions={rowsPerPageOptions}
										onPaginationModelChange={handlePaginationModelChange}
										sortingMode='server'
										onSortModelChange={handleHeaderFilterChange}
										sortModel={sortModel}
									/>
								</FilterProvider>
							)}
						</>
					)}
				</Pagination>
			</CardContent>
			{renderModal()}
		</Card>
	);
};

const mapStateToProps = ({ accounts, users, auth, rules }) => ({
	account: accounts.singleAccount,
	transactionFilters: accounts.transactionFilters,
	getAccountStatus: accounts.getSingleAccountStatus,
	getAttachedDevicesStatus: accounts.getAttachedDevicesStatus,
	deleteAccountStatus: accounts.deleteAccountStatus,
	users: users.data,
	userInfo: auth.userInfo,
	createEvidenceStatus: accounts.createEvidenceStatus,
	singleAccountDevices: accounts.singleAccountDevices,
	singleAccountTransactions: accounts.singleAccountTransactions,
	exportTransactionsToJSONStatus: accounts.exportTransactionsToJSONStatus,
	exportTransactionsToCSVStatus: accounts.exportTransactionsToCSVStatus,
	evidenceHistory: accounts.evidenceHistory,
	rules: rules.data.rules ? rules.data.rules : null,
});

const mapDispatchToProps = (dispatch) => ({
	getSingleAccount: (id) => dispatch(getSingleAccount(id)),
	getDevicesAttachedToAccounts: (body) =>
		dispatch(getDevicesAttachedToAccounts(body)),
	deleteEvidence: (body, onLoad) => dispatch(deleteEvidence(body, onLoad)),
	addEvidenceComment: (body, onLoad) =>
		dispatch(addEvidenceComment(body, onLoad)),
	getAccountTransactions: (body) => dispatch(getAccountTransactions(body)),
	setSingleAccount: (account) => dispatch(setSingleAccount(account)),
	setGetSingleAccountStatus: (status) =>
		dispatch(setGetSingleAccountStatus(status)),
	deleteAccount: (id, transactions, onLoad, refreshPage) =>
		dispatch(deleteAccount(id, transactions, onLoad, refreshPage)),
	changeFilterField: (value) => dispatch(changeTransactionFilterField(value)),
	resetTransactionFilters: (body) => dispatch(resetTransactionFilters(body)),
	exportTransactionsToJSON: (filters, sorting, accountId, onLoad) =>
		dispatch(exportTransactionsToJSON(filters, sorting, accountId, onLoad)),
	copyAccountEvidence: (name, id, onLoad, onSuccess) =>
		dispatch(copyEvidence(name, id, onLoad, onSuccess)),
	exportTransactionsToCSV: (filters, sorting, accountId, onLoad) =>
		dispatch(exportTransactionsToCSV(filters, sorting, accountId, onLoad)),
	getAccountsEvidenceHistory: (body) =>
		dispatch(getAccountsEvidenceHistory(body)),
	getRules: (transactionFilters) => dispatch(getRules(transactionFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);
