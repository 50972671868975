import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Grid,
	Typography,
	TableRow,
	TableCell,
	Card,
	CardHeader,
	CardContent,
} from '@mui/material';
import momentTimezone from 'moment-timezone';
import {
	getSingleTransaction,
	setSingleTransaction,
	setGetSingleTransactionStatus,
} from 'actions/transactionsActions';
import { LOADING, ERROR } from 'constants/apiStatuses';
import transformAgeInMs from 'helpers/transformAgeInMs';
import { Device, Account } from 'containers';
import './styles.css';
import CustomTransactionTable from './components/TranasctionTable';
import oldSessionFields from 'constants/oldSessionFields';
import { dateFormatter } from 'helpers/formatters';
import { GoogleMap } from 'components';
import { getRowColorByScore } from 'helpers/getTableRowColor';
import MatchingRulesTable from './components/MatchingRulesTable';
import KeyValueTable, { RowValue } from 'components/KeyValueTable';
import IpEnrichmentBlock from './components/IpEnrichmentBlock';
import { useTheme } from '@mui/material/styles';
import { setScoreIndicatorStyle } from 'helpers/scoreIndicator';

const Transaction = (props) => {
	const navigate = useNavigate();
	const params = useParams();
	const theme = useTheme();

	const {
		transaction,
		getTransactionStatus,
		getSingleTransaction,
		setSingleTransaction,
		setGetSingleTransactionStatus,
		ruleKeys,
	} = props;

	const handleUserNameClick = () => {
		navigate(`/accounts/${transaction.account.id}`);
	};

	const handleDeviceIdClick = () => {
		navigate(`/devices/${transaction.device.id}`);
	};

	const handleGoBack = () => navigate(-1);

	useEffect(() => {
		return () => {
			setSingleTransaction(null);
			setGetSingleTransactionStatus(LOADING);
		};
	}, []);

	useEffect(() => {
		getSingleTransaction(params.id);
	}, [params]);

	if (getTransactionStatus === LOADING) {
		return <div>Loading...</div>;
	}

	const highlightTextStyle = {
		color:
			theme.palette.mode === 'dark' ? theme.palette.primary.main : '#0000EE',
	};

	const dynamicStyles = {
		...setScoreIndicatorStyle({
			marginLeft: '10px',
			width: '30px',
			height: '30px',
			borderRadius: '15px',
			...(theme.palette.mode === 'dark' ? { border: '1px solid white' } : {}),
		}),
	};

	if (getTransactionStatus === ERROR) {
		return (
			<Box className='containerError'>
				<div>Transaction with id {params.id} does not exist</div>
				<Button color='primary' variant='contained' onClick={handleGoBack}>
					Go back
				</Button>
			</Box>
		);
	}

	const {
		ipEnrichment,
		connectionIP,
		debug,
		fpAgeInMs,
		ipReputation,
		serverTime,
		transactionsByAccount,
		transactionsByDevice,
		transactionsByIP,
		countriesByDeviceReport,
		countriesByAccountReport,
		accountsByDeviceReport,
		devicesByAccountReport,
		transactionsByDeviceByRS,
		transactionsByAccountByRS,
		transactionsByIPByRS,
		countriesByAccountByRSReport,
		countriesByDeviceByRSReport,
		...restSession
	} = transaction?.session ?? {};

	const allRuleKeys = [...ruleKeys, ...oldSessionFields];
	const titleClassName = `pageTitle ${getRowColorByScore({
		row: { score: transaction.rating.score },
	})}`;

	const pageTitle = (
		<Typography variant='h3' className={titleClassName}>
			Transaction
			<span className={'scoreIndicator'} />
		</Typography>
	);
	const tableData = {
		transactionsByAccount,
		transactionsByDevice,
		transactionsByIP,
		transactionsByAccountByRS,
		transactionsByDeviceByRS,
		transactionsByIPByRS,
		countriesByAccountReport,
		countriesByDeviceReport,
		countriesByAccountByRSReport,
		countriesByDeviceByRSReport,
		accountsByDeviceReport,
		devicesByAccountReport,
	};
	return (
		<Card>
			<CardHeader title={pageTitle} sx={dynamicStyles} />
			<CardContent>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<KeyValueTable width={30}>
							<TableRow>
								<TableCell>Account name</TableCell>
								<TableCell>
									<span
										onClick={handleUserNameClick}
										className='highlightText'
										style={highlightTextStyle}
									>
										{transaction.account.name}
									</span>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Device id:</TableCell>
								<TableCell>
									{transaction.device.id ? (
										<span
											onClick={handleDeviceIdClick}
											className='highlightText'
											style={highlightTextStyle}
										>
											{transaction.device.id}
										</span>
									) : (
										'n/a'
									)}
								</TableCell>
							</TableRow>
							<RowValue
								oKey='Creation date:'
								oValue={dateFormatter(transaction.creationDate)}
							/>
							<RowValue
								oKey='Timezone:'
								oValue={`UTC ${momentTimezone
									.tz(ipEnrichment.timeZone)
									.format('Z')} ${ipEnrichment.timeZone}`}
							/>
							<RowValue
								oKey='Server time:'
								oValue={dateFormatter(serverTime)}
							/>
							<RowValue oKey='Connection ip:' oValue={connectionIP} />
							<RowValue oKey='Ip reputation:' oValue={ipReputation} />
							<RowValue oKey='Age:' oValue={transformAgeInMs(fpAgeInMs)} />
						</KeyValueTable>
						<Box className='transInfoBlock'>
							<CustomTransactionTable data={tableData} />
						</Box>
						<KeyValueTable>
							{Object.entries(restSession).map(([sessionKey, value]) => {
								const ruleKey = allRuleKeys.find(
									({ key }) => key === `session.${sessionKey}`
								);
								return (
									<RowValue
										oKey={ruleKey ? ruleKey.label : sessionKey}
										oValue={value}
										key={sessionKey}
									/>
								);
							})}
						</KeyValueTable>
					</Grid>
					<Grid item xs={6}>
						<KeyValueTable>
							<RowValue oKey='Rule-Sets:' oValue={transaction.rating.ruleSet} />
							{transaction.rating.score !== undefined ? (
								<RowValue oKey='Score:' oValue={transaction.rating.score} />
							) : (
								<RowValue oKey='Score:' oValue='n/a' />
							)}
						</KeyValueTable>
						{!!transaction.rating.matchingRules.length && (
							<CardContent className='transInfoBlock'>
								Matching rules:{' '}
								<MatchingRulesTable
									matchingRules={transaction.rating.matchingRules}
								/>
							</CardContent>
						)}
						<CardContent>
							IP Information: <IpEnrichmentBlock ipEnrichment={ipEnrichment} />
						</CardContent>
						<CardContent>
							<GoogleMap location={ipEnrichment.location} />
						</CardContent>
					</Grid>
				</Grid>
			</CardContent>
			{transaction.device?.id && (
				<Box className='devicePage'>
					<Device id={transaction.device.id} resetVector={false} />
				</Box>
			)}
			{transaction.account?.id && (
				<Box className='accountPage'>
					<Account
						id={transaction.account.id}
						deleteButton={false}
						isTransaction={true}
					/>
				</Box>
			)}
		</Card>
	);
};

const mapStateToProps = ({ transactions, rules }) => ({
	transaction: transactions.singleTransaction,
	getTransactionStatus: transactions.getSingleTransactionStatus,
	ruleKeys: rules.ruleKeys || [],
});

const mapDispatchToProps = (dispatch) => ({
	getSingleTransaction: (id) => dispatch(getSingleTransaction(id)),
	setSingleTransaction: (transactions) =>
		dispatch(setSingleTransaction(transactions)),
	setGetSingleTransactionStatus: (status) =>
		dispatch(setGetSingleTransactionStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
