import { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import addQueryToSearchParams from 'helpers/addQueryToSearchParams';
import _ from 'lodash';
import {
	rowsPerPageOptions,
	evidenceHistoryOptions,
	aftTimeoutMs,
} from 'constants/tablesVariables';
import validateFilters, {
	processValidation,
} from 'helpers/validations/validateFilters';
import normalizeHeaderSortField from 'constants/headerSortFields';
import { VALIDATION_TYPES } from '../../helpers/validations/validateFilters';

const usePrevious = (value) => {
	const ref = useRef(value);
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
};

const Pagination = (props) => {
	const {
		getItems,
		children,
		changeFilterField,
		filters,
		initialFilters,
		defaultSortModel = [],
		validationType = VALIDATION_TYPES.DEFAULT,
		resetFilters,
		urlToReset,
		exception = null,
		accountId = null,
		isEvidence = false,
		setExternalSortModel = () => {},
	} = props;

	const didMountRef = useRef(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [page, setPage] = useState(0);
	const [isResettingFilters, setIsResettingFilters] = useState(false);

	const defaultPageOptions = isEvidence
		? evidenceHistoryOptions
		: rowsPerPageOptions;
	const [pageSize, setPageSize] = useState(defaultPageOptions[0]);
	const [pagesCursors, setPagesCursors] = useState([]);

	const [sortModel, setSortModel] = useState([]);

	const [defaultCursor, setDefaultCursor] = useState({
		pit: null,
		search_after: [null, null],
	});

	const timer = useRef(null);
	const [isArrowBlocked, setArrowBlocked] = useState(false);

	const queryFilters = !didMountRef.current ? initialFilters : filters;
	const prevFilters = usePrevious(filters);
	const prevAccountId = usePrevious(accountId);

	const [sortServerModel, setSortServerModel] = useState(defaultSortModel);
	const typedFilterValidator = validateFilters(validationType);

	const isFetchEvidences = isEvidence && !didMountRef.current;

	const reset = () => {
		setPagesCursors([]);
		setDefaultCursor({
			pit: null,
			search_after: [null, null],
		});
	};

	const handlePaginationModelChange = (params) => {
		const { page, pageSize } = params;

		handlePage(page, pageSize);

		setPage(page);
		setPageSize(pageSize);
	};

	const changeCursors = (data) => {
		setDefaultCursor(data.cursor);
		setPagesCursors((s) => {
			return data.refresh
				? [
						{
							page: data.page,
							search_after: data.cursor.search_after,
						},
				  ]
				: !s[data.page]
				? [
						...s,
						{
							page: data.page,
							search_after: data.cursor.search_after,
						},
				  ]
				: [...s];
		});
	};

	const request = ({
		cursor,
		page,
		pageSize,
		refresh,
		isSetData = true,
		...otherParams
	}) => {
		clearTimeout(timer.current);
		setArrowBlocked(false);
		getItems({
			cursor,
			page,
			pageSize,
			refresh,
			changeCursors,
			isSetData,
			...otherParams,
		});
		timer.current = refreshAFKTimer();
	};

	const handlePage = async (pageData, pageSize) => {
		if (pageData === 0) {
			request({
				cursor: { pit: null, search_after: [null, null] },
				page: 0,
				pageSize,
				filters,
				isSetData: true,
				sorting: sortServerModel,
			});
			setPage(pageData);
			return;
		}
		if (pagesCursors[pageData - 1]) {
			request({
				cursor: {
					pit: defaultCursor.pit,
					search_after: pagesCursors[pageData - 1].search_after,
				},
				page: pageData,
				pageSize,
				filters,
				isSetData: true,
				sorting: sortServerModel,
			});
			setPage(pageData);
		} else {
			request({
				cursor: defaultCursor,
				page: pageData,
				pageSize,
				filters,
				isSetData: true,
				sorting: sortServerModel,
			});
			setPage(pageData);
		}
	};
	const handleChangeFilters = (e) => {
		setPage(0);

		// Handle the case where e is an object
		if (typeof e === 'object' && e !== null && !Array.isArray(e)) {
			changeFilterField({ key: e.target.name, value: e.target.value });
		}

		// Handle the case where e is an array
		if (Array.isArray(e) && e.length > 0) {
			const updatedArray = e.map((item) => ({
				key: item.name,
				value: item.value,
			}));
			changeFilterField(updatedArray);
		} else {
			changeFilterField([{ key: 'ruleName', value: '' }]);
		}
	};

	const handleRefresh = (pageSize, sorting = sortServerModel) => {
		request({
			cursor: {
				pit: null,
				search_after: [null, null],
			},
			page: 0,
			pageSize,
			refresh: true,
			filters,
			sorting,
		});
		setPage(0);
	};

	const handleAddQuery = (e) => {
		const data = { [e.target.name]: e.target.value };
		const validationErrors = typedFilterValidator(data);

		if (!Object.keys(validationErrors).length) {
			const newQuery = addQueryToSearchParams(location.search, {
				value: e.target.value,
				name: e.target.name,
			});

			navigate(`${location.pathname}${newQuery}`);
		}
	};

	const handleResetFilters = () => {
		if (urlToReset) {
			navigate(urlToReset);
		}
		if (prevFilters === queryFilters) {
			setIsResettingFilters(true);
			request({
				cursor: {
					pit: null,
					search_after: [null, null],
				},
				page: 0,
				pageSize,
				refresh: true,
				filters: {},
				sorting: defaultSortModel,
			});
		}
		setSortModel([]);
		setSortServerModel(defaultSortModel);
		resetFilters(null);
		setPage(0);
	};

	const refreshAFKTimer = () => {
		return setTimeout(() => {
			setArrowBlocked(true);
		}, aftTimeoutMs);
	};

	useEffect(() => {
		timer.current = refreshAFKTimer();
		return () => {
			reset();
			clearTimeout(timer.current);
		};
	}, []);

	const debounceRequest = useCallback(
		_.debounce((filters) => {
			const validationErrors = typedFilterValidator(filters);
			processValidation(validationErrors, filters);

			if (!Object.keys(validationErrors).length) {
				request({
					cursor: {
						pit: null,
						search_after: [null, null],
					},
					page: 0,
					pageSize,
					filters,
					refresh: true,
					sorting: sortServerModel,
				});
			}
		}, 1000),
		[pageSize, sortServerModel]
	);

	const handleHeaderFilterChange = (column) => {
		const key = normalizeHeaderSortField(column[0]?.field);
		let sortingObject = defaultSortModel;
		if (key) {
			sortingObject = [{ [key]: { order: column[0].sort } }];
		}
		if (!_.isEqual(sortModel, column)) {
			setSortModel(column);
			setSortServerModel(sortingObject);
			setExternalSortModel(sortingObject);
			reset();
			handleRefresh(pageSize, sortingObject);
		}
	};

	const isInitialRequest = () => {
		return (
			(accountId !== null && accountId !== prevAccountId) || isFetchEvidences
		);
	};
	const isFiltersChange = () => {
		return prevFilters !== filters || !filters || exception === 'exception';
	};

	useEffect(() => {
		let shouldCallApi = true;
		if (isInitialRequest()) {
			request({
				cursor: {
					pit: null,
					search_after: [null, null],
				},
				page: 0,
				pageSize,
				refresh: true,
				filters: queryFilters,
				sorting: sortServerModel,
			});
		} else if (isFiltersChange() && !isResettingFilters) {
			debounceRequest(queryFilters);
			setPage(0);
		}

		didMountRef.current = true;

		return () => {
			if (shouldCallApi) {
				setIsResettingFilters(false);
			}
		};
	}, [filters, accountId]);

	const getPaginationProps = () => ({
		page,
		handleChangeFilters,
		handlePage,
		handleRefresh,
		handleAddQuery,
		handleResetFilters,
		pageSize,
		rowsPerPageOptions: defaultPageOptions,
		isArrowBlocked,
		handleHeaderFilterChange,
		handlePaginationModelChange,
		sortModel,
	});

	return children(getPaginationProps());
};

export default Pagination;
