import React from 'react';
import { connect } from 'react-redux';
import {
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Tooltip,
} from '@mui/material';
import './styles.css';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
	ReceiptLong as ReceiptLongIcon,
	Devices as DevicesIcon,
	SupervisorAccount as SupervisorAccountIcon,
	NoAccounts as NoAccountsIcon,
	Equalizer as EqualizerIcon,
	HistoryEdu as HistoryEduIcon,
	ManageAccounts as ManageAccountsIcon,
	History as HistoryIcon,
	Upload as UploadIcon,
	AccountTree as AccountTreeIcon,
	QueryStats as QueryStatsIcon,
	BugReport as BugReportIcon,
	EditLocationAlt as EditLocationAltIcon,
	ListAlt as ListAltIcon,
	Language as LanguageIcon,
	Public as PublicIcon,
	StarRate as StarRateIcon,
	DeviceHub as DeviceHubIcon,
	Hub as HubIcon,
	ManageHistory as ManageHistoryIcon,
	Fingerprint as FingerprintIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const Navbar = (props) => {
	const { userInfo, menuState } = props;
	const location = useLocation();
	const currentPage = location.pathname + location.search;

	const [activeListItem, setActiveListItem] = React.useState('');
	const theme = useTheme();

	const drawerBackground = menuState ? 'drawerOpen' : 'drawerClose';
	const drawerClass = `drawer ${drawerBackground}`;

	const drawerPaperClass = {
		paper: `drawerPaper ${drawerBackground}`,
	};
	const tooltipClasses = { tooltip: 'tooltip' };
	const listClass = `${menuState ? 'linksList' : ''}`;

	const isAdmin = userInfo?.role === 'admin';

	React.useEffect(() => {
		setActiveListItem(currentPage);
	}, [currentPage]);

	const styles = {
		listItem: {
			'&:hover': {
				backgroundColor: theme.palette.default.main,
			},
		},
		activeListItem: {
			backgroundColor: theme.palette.default.main,
		},
	};

	const CustomListItem = ({ to, title, short, icon }) => {
		const text = menuState ? title : short || title;
		const isActive = activeListItem.includes(to);

		const mergedStyles = isActive
			? { ...styles.listItem, ...styles.activeListItem }
			: styles.listItem;

		return (
			<Tooltip title={title} classes={tooltipClasses} placement='right'>
				<ListItem button component={RouterLink} to={to} sx={mergedStyles}>
					{icon && <ListItemIcon className='listItemIcon'>{icon}</ListItemIcon>}
					<ListItemText primary={text} />
				</ListItem>
			</Tooltip>
		);
	};

	return (
		<Drawer
			variant='permanent'
			className={drawerClass}
			classes={drawerPaperClass}
			open={menuState}
		>
			<Divider />
			<List className={listClass}>
				<CustomListItem
					to='/?tab=transactions'
					title='Transactions'
					icon={<ReceiptLongIcon />}
				/>
				<CustomListItem
					to='/?tab=devices'
					title='Devices'
					icon={<DevicesIcon />}
				/>
				<CustomListItem
					to='/?tab=accounts'
					title='Accounts'
					icon={<SupervisorAccountIcon />}
				/>
				<CustomListItem
					to='/?tab=ipBlocks'
					title='Ip Blocks'
					icon={<EditLocationAltIcon />}
				/>
				<CustomListItem
					to='/?tab=evidences'
					title='Evidences'
					icon={<NoAccountsIcon />}
				/>
				<CustomListItem
					to='/?tab=actions'
					title='AuditLog'
					icon={<HistoryIcon />}
				/>
				<CustomListItem
					to='/?tab=evidenceLog'
					title='EvidenceLog'
					icon={<ManageHistoryIcon />}
				/>
				<CustomListItem
					to='/?tab=reasons'
					title='Reasons'
					icon={<HistoryEduIcon />}
				/>

				<Divider />
				<CustomListItem
					to='/reports/ips-info'
					title='Ips'
					short='Ips'
					icon={<LanguageIcon />}
				/>
				<CustomListItem
					to='/reports/devices-info'
					title='Top Dev By Acc'
					short='Dev'
					icon={<AccountTreeIcon />}
				/>
				<CustomListItem
					to='/reports/accounts-info'
					title='Top Acc By Dev'
					short='Acc'
					icon={<PublicIcon />}
				/>
				<CustomListItem
					to='/reports/accounts-countries-info'
					title='Top Acc By Country'
					short='A/C'
					icon={<HubIcon />}
				/>
				<CustomListItem
					to='/reports/devices-countries-info'
					title='Top Dev By Country'
					short='D/C'
					icon={<DeviceHubIcon />}
				/>
				<CustomListItem
					to='/reports/most-used-rule'
					title='Top Rules'
					short='Rule'
					icon={<StarRateIcon />}
				/>
				<CustomListItem
					to='/reports/system-kpi'
					title='System KPI'
					icon={<EqualizerIcon />}
				/>
				<CustomListItem
					to='/reports/server-stats'
					title='Server stats'
					icon={<QueryStatsIcon />}
				/>
				<CustomListItem
					to='/fp-stats'
					title='Fp-Stats'
					icon={<FingerprintIcon />}
				/>
				<Divider />

				{/*<CustomListItem to='/rating' title='Rating' />*/}

				{isAdmin && (
					<CustomListItem
						to='/rating-test'
						title='Rating-Test'
						icon={<BugReportIcon />}
					/>
				)}
				<CustomListItem
					to='/role-manager'
					title='User Management'
					icon={<ManageAccountsIcon />}
				/>
				<CustomListItem
					to='/rule-manager'
					title='Rule Management'
					icon={<AccountTreeIcon />}
				/>
				<CustomListItem
					to='/rule-fields'
					title='Rule Fields'
					icon={<ListAltIcon />}
				/>
				{/*<CustomListItem
					to='/import-evidence'
					title='Import evidence'
					icon={<UploadIcon />}
				/>*/}
				<CustomListItem
					to='/new-import-evidence'
					title='Import in Evidence Queue'
					icon={<UploadIcon />}
				/>
			</List>
			<Divider />
		</Drawer>
	);
};

const mapStateToProps = ({ auth }) => ({
	menuState: auth.menuState,
	userInfo: auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
